import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/layout";
import Seo from "components/seo";
const ContactUs = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo
          title="Contact Us"
          description="Building Inspection Report Made Easy, Best for vehicle inspection report, rental inspection checklist, house inspection checklist and inspection report template. Try it FREE!"
        />
        <Helmet>
          <script src="https://desk.zoho.com/portal/api/feedbackwidget/507861000002912283?orgId=717976884&displayType=embeded" />
        </Helmet>
        <div className="h-screen flex items-center justify-center">
          <div className="sir-container space-y-6">
            <div className="text-center space-y-3">
              <h1 className="font-bold text-4xl">Contact Us</h1>
              <p>
                Fill up the form and our team will get back to you within 24
                hours.
              </p>
            </div>
            <div className="flex items-center justify-center relative z-0">
              <div className="min-h-[438px] sm:min-h-full w-full zoho-overide-style sm:h-96 bg-sir-lightGray rounded-xl">
                <div id="zsfeedbackwidgetdiv"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default ContactUs;
